var app = {
	windowLoaded: function () {
		_main = this;
		time = $('.guest').length?2000:400;
		setTimeout(function(){
			$('.preloader').fadeOut(500, function () {
				// $(this).remove();
			});
		}, time);		
	},
	windowResized: function () {
		_main = this;
		_main.getViewport();
	},
	windowScrolled: function () {
		_main = this;
	},
	cookies: {		
		createCookie: function(name, value, days) {		    
		    _main=app;
		    var date = new Date();
		    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		    var expires = "; expires=" + date.toGMTString();
		    document.cookie = name + "=" + value + expires + "; path=/";
		    (_main.debug)?console.log('creating cookie: ',name):'';
		},
		readCookie: function(name) {
		    _main=app;
		    var nameEQ = name + "=";
		    var ca = document.cookie.split(';');
		    for (var i = 0; i < ca.length; i++) {
		    	var c = ca[i];
		    	while (c.charAt(0) == ' ') c = c.substring(1, c.length);
		    	if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
		    }
		    return null;
		},
		checkCookies: function() {
		    _main=app;
		    if (_main.cookies.readCookie(_main.cookieName) != 1) {
		      (_main.debug)?console.log('cookie %s is not created yet',_main.cookieName):'';
		      _main.cookies.notification('show');
		    } else {
		    	(_main.debug)?console.log('cookie %s already exists',_main.cookieName):'';
		    }
		    $('.closeNotification').on('click',function () {
		      _main.cookies.createCookie(_main.cookieName, 1, 365);
		      _main.cookies.notification('hide');
		    });
		},
		notification: function(state) {
			_main=app;
			switch (state) {
				case 'show':
					$('footer').append('<div class="cookiesNotification"><p>Na stronie używamy plików cookies.</p><p class="closeNotification">OK &times;</p></div>');
				break;
				case 'hide':					
					$('.cookiesNotification').fadeOut(300, function () {
				        $(this).remove();
				      });
				break;
			}
		}
	},	
	helpers: {
		monthName: function(number) {			
			var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
			return months[number - 1];		  
		}
	},
	handleSliders: function() {		
		$('.slider-news').slick({
			arrows: false,
			autoplay: false,
			pauseOnFocus: false,
			pauseOnHover: false,
			dots: true
		});		
		$('.slider-hero').slick({
			arrows: false,
			autoplay: true,			
			pauseOnFocus: false,
			pauseOnHover: false,
			dots: true,
			fade: true
		});		
	},
	countDown: function() {
		var _main = this;
		// var countDownDate = new Date("Jan 5, 2022 10:00:00").getTime();

		if ($('#closest-event-details').length) {
			var start = $('#closest-event-details').attr('data-start');		
			
			var startDay = start.substr(0, start.indexOf('/'));
			var startMonth = start.match(/\/(.*)$/)[1];
			var startYear = startMonth.match(/\/(.*)$/)[1];
			var startMonth = startMonth.substr(0, start.indexOf('/'));		
			
			var monthName = _main.helpers.monthName(startMonth);
			var countDownDate = new Date(monthName+" "+startDay+", "+startYear+" 10:00:00").getTime();
			
			_main.debug?console.log('closest event raw: '+start):'';
			_main.debug?console.log('closest event start DAY: '+startDay):'';
			_main.debug?console.log('closest event start MONTH: '+startMonth):'';
			_main.debug?console.log('closest event start YEAR: '+startYear):'';
			_main.debug?console.log('closest event full: '+countDownDate):'';

			var x = setInterval(function() {
			
			var now = new Date().getTime();
			
			var distance = countDownDate - now;
			
			var days = Math.floor(distance / (1000 * 60 * 60 * 24));
			var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			var seconds = Math.floor((distance % (1000 * 60)) / 1000);
			
			$('#days').text(days);
			$('#hours').text(hours);
			$('#minutes').text(minutes);
			$('#seconds').text(seconds);
			
			
			if (distance < 0) {
				clearInterval(x);
				document.getElementById("count-down").innerHTML = "---";
			}
			}, 1000);
		}
	},
	handleTables: function() {
		if ($('.this-table').length) {			
			$('.this-table').DataTable({
				"retrieve": false,
				"lengthMenu": [10, 25, 50, 100, 150, 200, 300],
				"pageLength": 50,
				"order": [1, 'asc'],
				"language": {
					"url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Polish.json"
				}
			});
		}
	},
	events: function () {
		_main = this;
		

		$('.side-menu .parent a:first-child').on('click', function(e) {
			e.preventDefault();
			$(this).next('.sub-menu').toggleClass('reveal');
		});

		$('.search-trigger').on('click', function() {
			$("html, body").animate({
				scrollTop: 0
			}, 250);
			$('.search-box').toggleClass('reveal');
		});

		$('.shareBtn').on('click', function() {
			console.log('trigger');
			FB.ui({
				display: 'popup',
				method: 'share',
				// href: 'https://lodzkiejezdzieckie.pl',
				href: window.location.href,
			}, function(response){});
		});

		if (_main.viewport == 'desktop') {			
			$('a[href*="tel:"]').each(function() {
				$(this).on('click', function(e) {
					e.preventDefault();
				});				
			});
		}
		
		$('.mobile-trigger').on('click', function() {
			$('header nav').toggleClass('show');
			$('main').toggleClass('has-nav-active');
		});

	},
	getViewport: function () {
		_main = this;
		wh = $(window).width();
		if (wh <= _main.breakpointMobile) {
			_main.viewport = 'mobile';
		} else if (wh > _main.breakpointMobile && wh <= _main.breakpointTablet) {
			_main.viewport = 'tablet';
		} else {
			_main.viewport = 'desktop';
		}
		(_main.debug) ? console.log('viewport: ', _main.viewport) : '';		
	},
	init: function () {
		_main = this;
		_main.getViewport();
		_main.events();					
		_main.countDown();					
		_main.handleSliders();					
		_main.handleTables();					
		_main.cookies.checkCookies();
	},

	debug: true,
	viewport: null,
	globalNavState: false,
	breakpointMobile: 640,
	breakpointTablet: 1100,
	cookieName: 'ozj_website_2021'
}

$(document).ready(function () {
	app.init();
});

$(window).on('load', function () {
	app.windowLoaded();
});

$(window).resize(function () {
	app.windowResized();
});

$(document).scroll(function () {
	app.windowScrolled();
});
